<script lang="ts" setup>
import type { BlokRichText } from '~~/types/storyblok/bloks'

const props = defineProps<{ blok: BlokRichText | undefined }>()

const richText = computed(() => {
  return props.blok?.body ? renderRichText(props.blok.body) : ''
})

const maxWidthClass = computed(() => {
  return props.blok?.width === 'widest'
    ? 'max-w-prose-widest'
    : props.blok?.width === 'wide'
    ? 'max-w-prose-wide'
    : props.blok?.width === 'tight'
    ? 'max-w-prose-tight'
    : 'max-w-prose'
})

const horizontalAlignment = computed(() => {
  return props.blok?.horizontal_alignment === 'text-center'
    ? 'text-center'
    : props.blok?.horizontal_alignment === 'text-right'
    ? 'text-right'
    : 'text-left'
})
</script>

<template lang="pug">
section(v-editable="blok" class="blok-rich-text flex items-center justify-center")
  div(class="blok-rich-text__wrapper")
    hr(class="hidden col-span-1 m-0 mt-27" aria-hidden="true")

    div(class="blok-rich-text__body flex flex-col")
      div(
        v-html="richText"
        :class="[maxWidthClass, horizontalAlignment]"
      )
      UiButton(
        v-if="props.blok?.cta"
        v-for="cta in props.blok.cta"
        :blok="cta"
        :class="horizontalAlignment === 'text-center' ? 'self-center' : 'self-start'"
      )
</template>

<style lang="postcss">
.blok-rich-text {
  p {
    > a {
      @apply font-bold relative leading-none pb-3;
      background-image: linear-gradient(
        90deg,
        theme(colors.highlight),
        theme(colors.highlight)
      );
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 100% 3px;

      &:link,
      &:visited {
        @apply text-blue;
      }
      &:hover,
      &:active {
        @apply text-blue-dark;
      }
    }
  }
}
</style>
